<template>
    <div class="mproCtFive">
        <div class="mcFBox">
            <!-- 标题 -->
            <div class="mcFTitle wow animate__animated animate__slideInUp">
                <p class="mcFBig">网络流量那么贵，企业做全网营销为什么就能获得精准的、巨大的流量？</p>
                <p class="mcFSmall">
                    因为散发企业宣传品、参加展览会，花费已经出去了，威有只是用了技术手段和分析工具，
                    帮助您扩大了效果、提高了转化、加强了管理，并不需要额外支付市场推广费用。
                </p>
            </div>
            <!-- 左边图文 -->
            <div class="mcFLeft wow animate__animated animate__slideInLeft">
                <img src="https://iv.vu818.com/img/vu818img1.jpg" alt="" class="mcFLImg" />
                <p class="mcFLText">企业宣传品、展览会已经花钱了，全网营销技术只是加倍效果</p>
            </div>
            <!-- 右边图文 -->
            <div class="mcFRight wow animate__animated animate__slideInRight">
                <p class="mcFRText">最被忽视的扫码，恰恰是线上线下打通、通向流量鸿沟的“引桥”</p>
                <img src="https://iv.vu818.com/img/vu818img2.jpg" alt="" class="mcFRImg" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MproCtFive',
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: 'wow',
            animateClass: 'animate__animated',
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less">
.mproCtFive {
    width: 100%;
    height: 800px;
    margin-top: 50px;
    .mcFBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        // 标题
        .mcFTitle {
            width: 60%;
            height: 200px;
            margin: 0 auto;
            margin-top: 50px;
            .mcFBig {
                width: 100%;
                height: 100px;
                line-height: 50px;
                text-align: center;
                font-size: 36px;
                font-weight: 900;
            }
            .mcFSmall {
                width: 100%;
                height: 50px;
                line-height: 25px;
                text-align: center;
                margin-top: 30px;
            }
        }
        // 左边图文
        .mcFLeft {
            width: 40%;
            height: 80%;
            float: left;
            margin-top: 100px;
            position: relative;
            .mcFLImg {
                margin-left: 50px;
            }
            .mcFLText {
                width: 300px;
                height: 50px;
                line-height: 25px;
                position: absolute;
                bottom: 250px;
                left: 250px;
                font-weight: 900;
            }
        }
        // 右边图文
        .mcFRight {
            width: 40%;
            height: 50%;
            float: right;
            margin-right: 100px;
            margin-top: 17%;
            position: relative;
            .mcFRImg {
                margin-right: 50px;
            }
            .mcFRText {
                width: 300px;
                height: 50px;
                line-height: 25px;
                position: absolute;
                top: -80px;
                right: 100px;
                font-weight: 900;
            }
        }
    }
}
</style>
