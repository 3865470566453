<template>
    <div class="mproCtSeven">
        <div class="mcSBox">
            <!-- 标题 -->
            <div class="mcSTitle wow animate__animated animate__slideInUp">
                <p class="mcSBig">威有企业宣传品设计优势</p>
                <p class="mcSSmall">
                    我们从LOGO设计、名片（卡片）设计制作，都基于大数据实现了客户
                    AI自助，大大降低了高频次、小批量的交付成本。宣传单页、画册、海报等复杂宣传品
                    也实现了设计人工化、需求沟通智能化、生产交付全自动化的互联网改造。
                </p>
                <p class="mcSSmall">
                    威有有创意设计能力，有技术（尤其是对流量、访问及客情数据
                    分析能力）、有智能化的宣传品制作平台、有互联网化的生产支付，充分保障客户线下宣传与 线下宣传的密切配合。
                </p>
            </div>
            <!-- 图片 -->
            <div class="mcSBottom">
                <img
                    src="https://iv.vu818.com/img/vu818img3.jpg"
                    alt=""
                    class="mcSImg wow animate__animated animate__slideInUp"
                    data-wow-delay="0.5s"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MproCtSeven',
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: 'wow',
            animateClass: 'animate__animated',
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less">
.mproCtSeven {
    width: 100%;
    height: 800px;
    margin-top: 50px;
    .mcSBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        // 标题
        .mcSTitle {
            width: 70%;
            height: 200px;
            margin: 0 auto;
            .mcSBig {
                width: 100%;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-size: 36px;
                font-weight: 900;
                margin-bottom: 40px;
            }
            .mcSSmall {
                width: 100%;
                height: 50px;
                line-height: 25px;
                text-align: center;
            }
        }
        // 图片
        .mcSBottom {
            width: 80%;
            height: 500px;
            text-align: center;
            margin: 0 auto;
            margin-top: 100px;
        }
    }
}
</style>
